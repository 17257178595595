import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from './index';

LiveVideoPlayer.propTypes = {
  autoplay: PropTypes.oneOf([false, true, 'muted', 'play', 'any']),
  onSuccess: PropTypes.func,
  playerSettings: PropTypes.shape({
    brightcoveAccount: PropTypes.string,
    brightcoveAdConfigId: PropTypes.string,
    brightcoveApplicationId: PropTypes.string,
    brightcoveEmbed: PropTypes.string,
    brightcoveVideoId: PropTypes.string,
    brightcovePlayerId: PropTypes.string,
    defaultVolume: PropTypes.number,
  }),
  videoId: PropTypes.string,
};

function LiveVideoPlayer(props) {
  return (
    <VideoPlayer live autoplay='muted' {...props} />
  );
}

export default LiveVideoPlayer;
