import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayerLoader from '@brightcove/react-player-loader';

VideoPlayer.propTypes = {
  videoId: PropTypes.string,
  autoplay: PropTypes.oneOf([false, true, 'muted', 'play', 'any']),
  onSuccess: PropTypes.func,
  live: PropTypes.bool,
  onEnded: PropTypes.func,
  playerSettings: PropTypes.shape({
    brightcoveAccount: PropTypes.string,
    brightcoveAdConfigId: PropTypes.string,
    brightcoveApplicationId: PropTypes.string,
    brightcoveEmbed: PropTypes.string,
    brightcoveVideoId: PropTypes.string,
    brightcovePlayerId: PropTypes.string,
    defaultVolume: PropTypes.number,
  }),
};

function VideoPlayer({
  videoId,
  autoplay = 'any',
  onSuccess,
  live = false,
  playerSettings = null,
  onEnded,
  ...props
}) {
  const playerRef = useRef(null);
  const onEndedRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  // Use effect for watching prop changes of videojs event handler callbacks.
  useEffect(() => {
    // If videojs player ref is not loaded yet, do nothing.
    if (!playerRef.current) {
      return;
    }

    if (onEnded) {
      // If we already have an event listener bound from prior prop value, remove it.
      if (onEndedRef.current) {
        playerRef.current.off('ended', onEndedRef.current);
      }

      playerRef.current.on('ended', onEnded);
      onEndedRef.current = onEnded;
    } else if (onEndedRef.current) {
      playerRef.current.off('ended', onEndedRef.current);
      onEndedRef.current = null;
    }

    return () => {
      if (playerRef.current && onEndedRef.current) {
        playerRef.current.off('ended', onEndedRef.current);
        onEndedRef.current = null;
      }
    };
  }, [onEnded]);

  if (!isMounted) {
    return null;
  }

  const {
    brightcoveAccount,
    brightcoveAdConfigId,
    brightcoveApplicationId,
    brightcoveEmbed,
    brightcovePlayerId,
    brightcoveVideoId,
  } = playerSettings;

  const handleSuccessEvent = (success) => {
    playerRef.current = success.ref;

    if (playerRef.current) {
      // Setup callbacks.
      if (onEnded) {
        playerRef.current.on('ended', onEnded);
        onEndedRef.current = onEnded;
      }
    }

    onSuccess && onSuccess(success);
  }

  return (
    <ReactPlayerLoader
      accountId={brightcoveAccount}
      videoId={videoId || brightcoveVideoId}
      adConfigId={brightcoveAdConfigId}
      applicationId={brightcoveApplicationId}
      embedId={brightcoveEmbed}
      playerId={brightcovePlayerId}
      onSuccess={handleSuccessEvent}
      options={{
        aspectRatio: '16:9',
        autoplay,
      }}
      {...props}
    />
  );
}

export default VideoPlayer;
