import React, { useState } from 'react';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { Col, Form, Row, Input } from 'reactstrap';
import SectionsWrapper from 'components/SectionsWrapper';
import ReadyToTrade from 'components/ReadyToTrade';
import Section from 'components/Section';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import SEO from 'components/SEO';
import TastyButton from 'components/TastyButton';
import MailchimpForm from 'components/MailchimpForm';
import ImageHoverSwap from 'components/ImageHoverSwap';
import LiveVideoPlayer from 'components/VideoPlayer/LiveVideoPlayer';
import inPlatformStatic from 'images/inspiration-static-.png';
import inPlatformAnimated from 'images/inspiration-animated-.gif';
import followFeedStatic from 'images/inspiration-follow-feed-static.png';
import followFeedAnimated from 'images/inspiration-follow-feed-animated.gif';
import inspirationHero from 'images/image-inspiration-hero.jpg';
import tastyworksLearnImage from 'images/GFX_Learning.png';
import tastyworksResearchImage from 'images/TW-AUS-GFX-Research Heading.png';
import tastyworksVideoImage from 'images/twl-Video-GFX.png'
import tastyworksEbooksImage from 'images/GFX_ebook2.png';
import audioIcon from 'images/icon-listen.svg';
import externalLinks from 'utils/externalLinks';
import { BRIGHTCOVE_SETTINGS, MAILCHIMP_FORM_URL } from 'utils/constants';
import { useLayoutContext } from 'context/LayoutContext';
import SmallPrint from 'components/SmallPrint';

const InspirationSection = styled(Section)`
  padding: 3.125rem;
  background-color: ${prop('theme.colors.white')};

  &:nth-child(even) {
    background-color: ${prop('theme.colors.lighterGray')};
  }
`;

const Emphasis = styled.span`
  font-family: ${prop('theme.fonts.default')};
  font-style: italic;
`;

const StyledCol = styled(Col)`
  &:first-child {
    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-top: 2rem;
    }
  }
`;

const VideoOverlay = styled.div`
  align-items: center;
  background-color: rgba(24, 24, 24, 0.49);
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

const UnmuteIcon = styled.img`
  cursor: pointer;
`;

const ImageContainer = styled.div`
  min-height: 168px;
  margin-bottom: 0.5rem;

  img {
    height: auto;
    min-height: inherit;
    object-fit: cover;
    width: 100%;
  }
`;

const EbookContainer = styled.div`
  text-align: right;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    text-align: center;
  }

  img {
    max-width: 85%;

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      max-width: 60%;
    }

    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      max-width: 80%;
    }
  }
`;

const SignUpRow = styled(Row)`
  padding-top: 2rem;
`;

const FeedTitle = styled.h3`
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
  font-weight: 600;

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    margin-top: 0;
    margin-bottom: .5rem;
  }
`;

const LearnButton = styled(TastyButton)`
  background-color: ${prop('theme.colors.primaryRed')};
  margin-top: 1rem;
  width: 100%;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    width: auto;
  }
`;

const LearnCol = styled(Col)`
  @media (max-width: ${prop('theme.breakpoints.md')}) {
    margin-bottom: 2rem;
  }

  &:last-child {
    @media (max-width: ${prop('theme.breakpoints.md')}) {
      margin-bottom: 0;
    }
  }
`;

function EducationPage({ showPromoBar }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;
  const [isMuted, setIsMuted] = useState(true);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState();

  const onVideoPlayerLoaded = (success) => {
    setPlayerLoaded(true);
    setVideoPlayer(success.ref);
  }

  if (videoPlayer && isMuted) {
    videoPlayer.muted(true);
  }

  const unmuteVideo = () => {
    if (videoPlayer) {
      // Unmute the player.
      videoPlayer.muted(false);
      videoPlayer.volume(BRIGHTCOVE_SETTINGS.defaultVolume);
    }

    setIsMuted(false);
  }


  return (
    <Layout>
      <SEO title='Education' canonicalHref='https://www.tastyworks.com.au/education' />
      <main>
        <Hero
          heroImage={inspirationHero}
          $showAlert={showAlert}
          showPromoBar={showPromoBar}
        >
          <h1>Education</h1>
          <h2>Freedom to trade smart</h2>
        </Hero>
        <SectionsWrapper noGutters>
          <InspirationSection>
            <Row className='flex-column-reverse flex-md-row align-items-center'>
              <StyledCol xs={12} md={7}>
                <h2>
                  Learn about option strategies for free at tastylive.
                </h2>
                <p>A financial network like no other.</p>
                <p>
                  tastytrade was born in 2017 but isn’t new to the options world. Its parent company,
                  tasty<em>live</em>, was formed in 2011 to help investors learn how to manage their own money.
                </p>
                <p>
                  Whether you’re a beginner or an experienced trader, tasty<em>live’s</em> live programming lets
                  you tune in and put concepts like volatility, probability, and strategy to work. You’ll
                  get so smart, you’ll send shivers through Wall Street. There's something new waiting whenever
                  you have time to watch.
                </p>
                <div className='d-flex justify-content-center'>
                  <LearnButton href={externalLinks.tastylive} target='_blank'>
                    Check out tastylive.com
                  </LearnButton>
                </div>
              </StyledCol>
              <StyledCol sm={12} md={5}>
                <div className='position-relative'>
                  <LiveVideoPlayer
                    autoplay='muted'
                    onSuccess={onVideoPlayerLoaded}
                    playerSettings={BRIGHTCOVE_SETTINGS}
                  />
                  {playerLoaded && isMuted && (
                    <VideoOverlay>
                      <UnmuteIcon alt='audio icon' onClick={unmuteVideo} src={audioIcon} />
                    </VideoOverlay>
                  )}
                </div>
              </StyledCol>
              <SmallPrint gutterTop>
                The information provided by tasty<em>live</em> is provided on behalf of tastytrade Australia.
                The information has been prepared without considering your objectives, financial situation or
                needs. Consequently, you should consider the information in light of your objectives, financial
                situation and needs.
              </SmallPrint>
            </Row>
          </InspirationSection>
          <InspirationSection withPadding>
            <Row>
              <LearnCol xs={12} md={4}>
                <ImageContainer>
                  <img alt='tastylive video content' src={tastyworksVideoImage} />
                </ImageContainer>
                <div className='text-center'>
                  <h2 className='text-uppercase'>Videos</h2>
                  <p>
                    Watch traders in real-time with daily live programming or
                    dig into a library of free on-demand videos covering a multitude of topics.
                  </p>
                </div>
              </LearnCol>
              <LearnCol xs={12} md={4}>
                <ImageContainer>
                  <img alt='tastylive learn center' src={tastyworksLearnImage} />
                </ImageContainer>
                <div className='text-center'>
                  <h2 className='text-uppercase'>Learning</h2>
                  <p>
                    Take free courses to learn how to trade options and futures the tasty<em>live</em> way,
                    no matter your experience level.
                  </p>
                </div>
              </LearnCol>
              <LearnCol xs={12} md={4}>
                <ImageContainer>
                  <img alt='tastylive research team' src={tastyworksResearchImage} />
                </ImageContainer>
                <div className='text-center'>
                  <h2 className='text-uppercase'>Research</h2>
                  <p>
                    A financial think tank that works daily to offer up actionable
                    trade ideas & strategies based on years of research.
                  </p>
                </div>
              </LearnCol>
            </Row>
          </InspirationSection>
          <InspirationSection withPadding>
            <h2 class='text-center'>
              Get trade ideas from tastylive right on your tastytrade trading platform.
            </h2>
            <Row>
              <Col xs={12} md={6}>
                <ImageHoverSwap
                  src={followFeedStatic}
                  hoverSrc={followFeedAnimated}
                  alt='Follow Feed feature'
                  className='w-100'
                />
                <div className='text-center'>
                  <FeedTitle>Follow Feed</FeedTitle>
                  <p>
                    The Follow Feed on the tastytrade platform lets you see
                    favorite trades from select traders. Whether it’s earnings plays,
                    pairs trades, or everyday strategies, you can see what they’re doing
                    and why they’re doing it.
                  </p>
                </div>
              </Col>
              <Col xs={12} md={6}>
                <ImageHoverSwap
                  src={inPlatformStatic}
                  hoverSrc={inPlatformAnimated}
                  alt='In-Platform Video Feed feature'
                  className='w-100'
                />
                <div className='text-center'>
                  <FeedTitle>In-platform Video Feed</FeedTitle>
                  <p>
                    Looking for inspiration? You can watch real traders trade
                    right inside the platform! Join informed conversations about
                    strategies, concepts, research, and market movements without
                    ever taking your eyes off of what you’re working on.
                  </p>
                </div>
              </Col>
            </Row>
          </InspirationSection>
          <ReadyToTrade />
        </SectionsWrapper>
      </main>
    </Layout>
  )
};

export default EducationPage;
