import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import parseQuery from 'utils/functions';

const SignupForm = styled(Form)`
  .form-group {
    margin-bottom: 0;
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-bottom: 2rem;
  }
`;

const EmailInput = styled(Input)`
  font-family: ${prop('theme.fonts.default')};

  &.form-control {
    border-radius: 0;

    @media (max-width: ${prop('theme.breakpoints.xs')}) {
      max-width: 8.875rem;
    }
  }
`;

const SubmitButton = styled(Button)`
  text-transform: uppercase;

  &.btn-secondary {
    background-color: ${prop('theme.colors.primaryRed')};
    border-color: ${prop('theme.colors.primaryRed')};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: 700;
  }
`;

const Response = styled.div`
  margin-top: 0.25rem;
  padding: 0.3125rem 0.625rem;
  width: 12.1875rem;
  color: ${prop('theme.colors.white')};
  font-weight: 700;
`;

const ErrorResponse = styled(Response)`
  background-color: ${prop('theme.colors.darkerRed')};
`;

const SuccessResponse = styled(Response)`
  background-color: ${prop('theme.colors.success')};
`;

MailchimpForm.propTypes = {
  formUrl: PropTypes.string,
  groupName: PropTypes.string,
  groupValue: PropTypes.string,
};

function initializeMailchimpScripts() {
  const existingScript = document.getElementById('mailchimpValidate');

  if (!existingScript) {
    // Add the mailchimp mc-validate.js script to the document.
    const script = document.createElement('script');
    script.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
    script.id = 'mailchimpValidate';
    script.onload = function () {
      (function ($) {
        window.fnames = [];
        window.ftypes = [];
        window.fnames[0] = 'EMAIL';
        window.ftypes[0] = 'email';
      })(window.jQuery);
      window.$mcj = window.jQuery.noConflict(true);
    };
    document.body.appendChild(script);
  }

  // Remove the MC script when component unmounts. This is required, so that
  // when navigating to a different page with an MC form the scripts are
  // re-added and re-run in order to bind to that form properly.
  return () => {
    const scriptToRemove = document.getElementById('mailchimpValidate');

    if (scriptToRemove) {
      document.body.removeChild(scriptToRemove);
    }
  };
}

function MailchimpForm({ formUrl, groupName, groupValue }) {
  useEffect(initializeMailchimpScripts, []);

  // Parse the query parameters from the MC form url, if available.
  // These are used for the bot protection input name since it needs to match
  // the form's params.
  const formUrlQueryString = formUrl.split('?')[1];
  const formUrlQueryParams = formUrlQueryString
    ? parseQuery(formUrlQueryString)
    : {};

  return (
    <div id='mc_embed_signup'>
      <SignupForm
        action={formUrl}
        className='validate'
        id='mc-embedded-subscribe-form'
        inline
        method='post'
        name='mc-embedded-subscribe-form'
        noValidate
        target='_blank'
      >
        {groupName && (
          // Hidden input field for the group field to save this contact in.
          <input name={groupName} type='hidden' value={groupValue} />
        )}
        {formUrlQueryParams && formUrlQueryParams.u && formUrlQueryParams.id && (
          // real people should not fill this in and expect good things - do not remove this or risk form bot signups
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
            <input
              type='text'
              name='b_d1f46bc08ad07a3549e29cbc4_112811d820'
              tabindex='-1'
              value=''
            />
          </div>
        )}
        <FormGroup>
          <EmailInput
            className='required email'
            id='mce-EMAIL'
            name='EMAIL'
            placeholder='Email Address'
            type='email'
            size='20'
          />
        </FormGroup>
        <SubmitButton id='mc-embedded-subscribe' name='subscribe' type='submit'>
          Sign Up
        </SubmitButton>
        <div className='clear' id='mce-responses'>
          <ErrorResponse
            className='response'
            id='mce-error-response'
            style={{ display: 'none' }}
          />
          <SuccessResponse
            className='response'
            id='mce-success-response'
            style={{ display: 'none' }}
          />
        </div>
      </SignupForm>
    </div>
  );
}

export default MailchimpForm;
